import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { Modal } from 'react-bootstrap';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

import { monthDayYearDateFormat } from 'sow/utils/dateTime';
import { planAppStateText } from 'sow/constants/planApp';

import Row from 'sow/components/molecules/Row';
import Column from 'sow/components/molecules/Column';
import FormikField from 'sow/components/organisms/FormikField';
import FormikReactSelect from 'sow/components/molecules/FormikReactSelect';
import FormikDate from 'sow/components/molecules/FormikDate';
import Widget from 'sow/components/molecules/Widget';
import WidgetHeading from 'sow/components/molecules/WidgetHeading';
import WidgetBody from 'sow/components/molecules/WidgetBody';
import ImprovedDataTable from 'sow/components/molecules/ImprovedDataTable';
import Table from 'sow/components/molecules/Table';
import TableRow from 'sow/components/atoms/TableRow';
import TableCell from 'sow/components/atoms/TableCell';
import Block from 'sow/components/atoms/Block';
import MulticolorPill from 'sow/components/molecules/MulticolorPill';
import Button from 'sow/components/atoms/Button';
import PriorityFlag from 'sow/components/molecules/PriorityFlag';

const formSchema = Yup.object({
  due_date: Yup.string()
    .matches(
      /^([0-9]{2}\/[0-9]{2}\/[0-9]{4})?$/,
      'Date is invalid. Please use MM/DD/YYYY.',
    )
    .nullable(),
});

const generateColumns = (isStaff, isStandardClient) => {
  const columns = [
    { data: 'id' },
    { data: 'priority_designation_type' },
    { data: 'org' },
    { data: 'due_date' },
    { data: 'noncompliant' },
    { data: 'anniversary_date' },
    { data: 'app_data' },
    { data: 'organization_types' },
    { data: 'worksheet' },
    { data: 'question' },
  ];

  if (!isStaff) columns.splice(0, 1);

  if (isStandardClient) {
    columns.splice(0, 1);
    columns.splice(1, 1);
  }

  return columns;
};

const generateColumnDefs = (isStaff, isStandardClient, toggleModal) => {
  const columnDefs = [
    isStaff && {
      targets: 0,
      sortable: false,
      createdCell: (td, cellData, rowData) => {
        ReactDOM.render(
          <a href="" onClick={event => toggleModal(event, rowData)}>
            Edit
          </a>,
          td,
        );
      },
    },
    !isStandardClient && {
      targets: isStaff ? 1 : 0,
      render: data => data.sort_order,
      createdCell: (td, cellData) => {
        if (cellData.id)
          return ReactDOM.render(<MulticolorPill pillData={cellData} />, td);
        return null;
      },
    },
    {
      targets: isStaff ? 2 : isStandardClient ? 0 : 1,
      render: data => `<a href="/org/${data.id}" target="_blank">${data.name}</a>`,
    },
    {
      targets: isStaff ? 4 : isStandardClient ? 1 : 3,
      render: data => {
        if (data) return 'Yes';
        return 'No';
      },
    },
    {
      type: 'date',
      targets: isStaff ? [3, 5] : isStandardClient ? [2] : [2, 4],
      render: data => {
        if (data) {
          return monthDayYearDateFormat(data);
        }
        return null;
      },
    },
    {
      targets: isStaff ? 6 : isStandardClient ? 3 : 5,
      createdCell: (td, cellData) => {
        if (!cellData) {
          ReactDOM.render(
            <Fragment>
              <p>Not Started</p>
            </Fragment>,
            td,
          );
        } else {
          ReactDOM.render(
            <Block className="osp-status-cell">
              <PriorityFlag
                flags={cellData.application_priority_flags}
                isClient={!isStaff}
              />
              <p>{planAppStateText[cellData.app_state]}</p>
            </Block>,
            td,
          );
        }
      },
      render: data => {
        if (!data) {
          return 'Not Started';
        } else {
          return planAppStateText[data.app_state];
        }
      },
    },
    {
      targets: isStaff ? 8 : isStandardClient ? 5 : 7,
      render: data => {
        if (data.location_id)
          return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}" target="_blank">${data.name}</a>`;
        return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}" target="_blank">${data.name}</a>`;
      },
    },
    {
      targets: isStaff ? 9 : isStandardClient ? 6 : 8,
      render: data => {
        if (data.location_id) {
          if (data.matrix_row_id)
            return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}#pa-wsmr--${data.matrix_row_id}" target="_blank">${data.name}</a>`;
          return `<a href="/org/${data.organization_id}/plan/${data.application_id}/location/${data.location_id}#pa-wsq--${data.question_id}" target="_blank">${data.name}</a>`;
        }
        if (data.question_id) {
          if (data.matrix_row_id)
            return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}#pa-wsmr--${data.matrix_row_id}" target="_blank">${data.name}</a>`;
          return `<a href="/org/${data.organization_id}/plan/${data.application_id}/worksheet/${data.worksheet_id}#pa-wsq--${data.question_id}" target="_blank">${data.name}</a>`;
        }
        return 'All worksheet questions have been marked to-do.';
      },
    },
  ];

  if (!isStaff) columnDefs.splice(0, 1);

  return columnDefs;
};

const TodoListTable = ({
  show,
  todoList,
  toggleModal,
  priorityDesignationTypeOptions,
  updateRequest,
  modalData,
  isStaff,
  isStandardClient,
}) => (
  <Fragment>
    <Modal
      className="search-filter-block"
      backdrop={'static'}
      show={show}
      onHide={event => toggleModal(event, null)}
    >
      <Modal.Header closeButton>
        <Modal.Title>Edit To-do</Modal.Title>
      </Modal.Header>
      <Formik
        initialValues={modalData}
        validationSchema={formSchema}
        onSubmit={updateRequest}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="search-filter-row">
                <Column className="column-no-margin" xs={12}>
                  <Field
                    label="Priority"
                    name={'priority_designation_type_id'}
                    component={FormikField}
                    type={FormikReactSelect}
                    options={priorityDesignationTypeOptions}
                    clearable={false}
                  />
                </Column>
                <Column className="column-no-margin" xs={12}>
                  <Field
                    className="input-filter"
                    label="Due Date"
                    name={'due_date'}
                    dateFormat={'MM/DD/YYYY'}
                    component={FormikField}
                    type={FormikDate}
                  />
                </Column>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row className="search-filter-row">
                <Column className="search-filter-column button-column" xs={12} md={4}>
                  <Row className="search-filter-row">
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button search-button"
                        type="submit"
                        primary
                      >
                        Save
                      </Button>
                    </Column>
                    <Column className="search-filter-column button-column" xs={6}>
                      <Button
                        className="filter-command-button reset-button"
                        onClick={toggleModal}
                        primary
                      >
                        Cancel
                      </Button>
                    </Column>
                  </Row>
                </Column>
              </Row>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
    <Widget>
      <WidgetHeading>To-do List</WidgetHeading>
      <WidgetBody>
        <ImprovedDataTable
          selector={'.todoListTable'}
          tableDef={{
            pageLength: 50,
            destroy: true,
            searching: false,
            data: todoList,
            deferRender: true,
            aaSorting: [],
            columns: generateColumns(isStaff, isStandardClient),
            columnDefs: generateColumnDefs(isStaff, isStandardClient, toggleModal),
          }}
        >
          <Table
            className={'todoListTable'}
            bordered
            striped
            condensed
            head={
              <TableRow>
                {isStaff && <TableCell heading textCenter></TableCell>}
                {!isStandardClient && (
                  <TableCell heading textCenter>
                    Priority
                  </TableCell>
                )}
                <TableCell heading textCenter>
                  Organization
                </TableCell>
                {!isStandardClient && (
                  <TableCell heading textCenter>
                    Due Date
                  </TableCell>
                )}
                <TableCell heading textCenter>
                  Noncompliant
                </TableCell>
                <TableCell heading textCenter>
                  Anniversary Date
                </TableCell>
                <TableCell heading textCenter>
                  OSP Status
                </TableCell>
                <TableCell heading textCenter>
                  Category
                </TableCell>
                <TableCell heading textCenter>
                  Worksheet
                </TableCell>
                <TableCell heading textCenter>
                  Question
                </TableCell>
              </TableRow>
            }
            style={{ width: '100%' }}
          >
            <TableRow></TableRow>
          </Table>
        </ImprovedDataTable>
      </WidgetBody>
    </Widget>
  </Fragment>
);

export default TodoListTable;
