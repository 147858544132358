const R = require('ramda');

const configTemplates = {
  all: {
    env: process.env.NODE_ENV || 'development',
    isDevEnv: process.env.NODE_ENV !== 'production',
    basename: '',
    isBrowser: typeof window !== 'undefined',

    deployBranch: process.env.CIRCLE_BRANCH || 'local',
    isDeployLocalDev: false,
    isDeployQA: false,
    isDeployAlpha: false,
    isDeployBeta: false,
    isDeployProd: false,

    apiRootUrl: process.env.API_URL || 'http://localhost:3002/api',
  },

  // -- envs
  envs: {
    test: {},
    development: {},
    production: {},
  },

  deployBranch: {
    local: {
      isDeployLocalDev: true,
    },
    qa: {
      isDeployQA: true,
    },
    alpha: {
      isDeployAlpha: true,
    },
    beta: {
      isDeployBeta: true,
    },
    production: {
      isDeployProd: true,
    },
  },
};

const baseConfig = R.mergeAll([
  configTemplates.all,
  configTemplates.deployBranch[configTemplates.all.deployBranch],
  configTemplates.envs[configTemplates.all.env],
]);

module.exports = R.merge(baseConfig, {
  apiUrl: `${baseConfig.apiRootUrl}/v1`,
});
